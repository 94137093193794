<template>
  <div id="body">
    <base-navigation></base-navigation>

    <base-card>
      <div id="element-to-convert">
          <div class="row">
        <div class="col-lg-6">
          <img
            width="120"
            height="100"
            src="../../assets/shangilia_logo.png"
          />
        </div>

        <div class="col-lg-6">
        <small> Shangilia community Welfare </small> 
          <br />
      <small>   Benevolent welfare for members </small> 
          <br />
      <small>  Along shangilia road </small> 
          <br />
      <small>   www.shangiliawelfare.co.ke, shangiliawelfare@gmail.com </small> 

          <!-- {{ memberdetails }} -->
        </div>
      </div>

        <div class="row">
          <div class="col-lg-7">
            <small>Membership Report</small>
          </div>
          <div class="col-lg-5 text-info" >Date <small> {{ Date().toLocaleString('nl-NL').split("GMT")[0] }}</small></div>
        </div>
        <div class="row">
          <div class="col-lg-4 text-primary">
            <small>Registration Fee:  <b class="text-danger">{{ total_registrationFee }}</b> </small>
          </div>
          <div class="col-lg-4 text-primary">
            <small>Buffer Amount:  <b class="text-danger"> {{ total_buffferAmount }}</b> </small>
          </div>
          <div class="col-lg-4 text-primary">
            <small>Total collected: <b class="text-danger">{{ total_registrationFee + total_buffferAmount + total_caseOneAmount +total_caseTwoAmount + total_caseThreeAmount+total_caseFourAmount + total_caseFiveAmount + total_caseSixAmount+ total_caseSevenAmount + total_caseEightAmount + total_caseNineAmount + total_caseElevenAmount +total_caseTwelveAmount + total_caseThirteenAmount + total_caseFourteenAmount + total_caseFifteenAmount+total_caseSixteenAmount+ total_caseSeventeenAmount + total_caseEighteenAmount + total_caseNinenteenAMount}}</b> </small>
          </div>
          <div class="col-lg-4 text-primary">
            <small>Registered Members: <b class="text-danger">{{ someMembers.length }}</b> </small>
          </div>
          <div class="col-lg-4 text-primary">
            <small>Forms filled: <b class="text-danger">{{ memberdetails.length }}</b> </small>
          </div>
          <div class="col-lg-4 text-primary">
            <small>Active Members: <b class="text-danger">{{ active_members.length }}</b>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 1 Total: <b class="text-danger">{{ total_caseOneAmount }}</b> <i>{{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==1)[0].payment_date.split("T")[0] }} </i> </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 2 Total: <b class="text-danger">{{ total_caseTwoAmount }}</b> <i>{{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==2)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 3 Total: <b class="text-danger">{{ total_caseThreeAmount }}</b><i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==3)[0].payment_date.split("T")[0] }}</i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 4 Total: <b class="text-danger">{{ total_caseFourAmount }}</b><i>{{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==4)[0].payment_date.split("T")[0] }} </i> </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 5 Total: <b class="text-danger">{{ total_caseFiveAmount }}</b> <i>{{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==5)[0].payment_date.split("T")[0] }}</i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 6 Total: <b class="text-danger">{{ total_caseSixAmount }}</b><i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==6)[0].payment_date.split("T")[0] }} </i> </small>
          </div>
           <div class="col-lg-3 text-secondary">
            <small>Case 7 Total: <b class="text-danger">{{ total_caseSevenAmount }}</b><i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==7)[0].payment_date.split("T")[0] }} </i> </small>
          </div>

          <div class="col-lg-3 text-secondary">
            <small>Case 8 Total: <b class="text-danger">{{ total_caseEightAmount }}</b> <i>{{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==8)[0].payment_date.split("T")[0] }} </i> </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 9 Total: <b class="text-danger">{{ total_caseNineAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==9)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 10 : <b class="text-danger">Paid</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==10)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>

          <div class="col-lg-3 text-secondary">
            <small>Case 11 Total: <b class="text-danger">{{ total_caseElevenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==11)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 12 Total: <b class="text-danger">{{ total_caseTwelveAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==12)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>

          <div class="col-lg-3 text-secondary">
            <small>Case 13 Total: <b class="text-danger">{{ total_caseThirteenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==13)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 14 Total: <b class="text-danger">{{ total_caseFourteenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==14)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>

          <div class="col-lg-3 text-secondary">
            <small>Case 15 Total: <b class="text-danger">{{ total_caseFifteenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==15)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 16 Total: <b class="text-danger">{{ total_caseSixteenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==16)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 17 Total: <b class="text-danger">{{ total_caseSeventeenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==17)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 18 Total: <b class="text-danger">{{ total_caseEighteenAmount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==18)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
          <div class="col-lg-3 text-secondary">
            <small>Case 19 Total: <b class="text-danger">{{ total_caseNinenteenAMount }}</b> <i> {{ this.$store.getters.cases.filter(vnumber=>vnumber.case_id==19)[0].payment_date.split("T")[0] }} </i>  </small>
          </div>
        </div>

        

        <br />

       <!-- payments {{ this.$store.getters.joiningMember(2) }} -->

        
        <table class="table myFormat table-striped table-bordered table-sm">
          <thead>
            <tr>
              <!-- <th><h3>Client Id</h3></th> -->
              <th><small>No.</small></th>
              <th><small>Name</small></th>
              <!-- <th><small> Institution Name</small></th> -->
              <th><small>Court</small></th>
              <th><small>Reg.</small></th>
              <th><small>Buffer</small></th>
              <!-- <th><small>C1</small></th>
              <th><small>C2</small></th>
              <th><small>C3</small></th>
              <th><small>C4</small></th>
              <th><small>C5</small></th>
              <th><small>C6</small></th>

              <th><small>C7</small></th>
              <th><small>C8</small></th>
              <th><small>C9</small></th>
              <th><small>C10</small></th> -->
              <th><small>C11</small></th>
              <th><small>C12</small></th>
              <th><small>C13</small></th>
              <th><small>C14</small></th>
              <th><small>C15</small></th>
              <th><small>C16</small></th>
              <th><small>C17</small></th>
              <th><small>C18</small></th>
              <th><small>C19</small></th>



              <!-- <th><h6>Application date</h6></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in memberdetails.filter(c1=>c1.case_1!=0)" :key="member.id">
              <!-- <td>{{ payment.client_id}}</td> -->
              <td>
                   <router-link
              :to="`/mpesa/payments/${member.membership_number}`"
            >
              <small> {{ member.membership_number }}</small>
            </router-link>
                
              </td>
              <td>
                <!-- <small>{{ member.memberNames.split(" ")[0]  }}</small> -->
                <small>{{ member.memberNames }}</small>

              </td>

              <td>
                <small>{{ member.memberCourt }}</small>
              </td>

              <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].registration_amount}}</small>
              </td>
              <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].buffer_amount }}</small>
              </td>
              
              <!-- <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_1_amount }}</small>

              </td>


              <td>
                  
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_2_amount }}</small>
             
              </td>
              <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_3_amount }}</small>
               
                </td>
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_4_amount }}</small>
               
                </td>
                  <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_5_amount }}</small>
               
                </td>  
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_6_amount }}</small>
               
                </td>
                 <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_7_amount }}</small>
               
                </td>
                  <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_8_amount }}</small>
               
                </td>
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_9_amount }}</small>
               
                </td>
                <td>
                  <router-link
              :to="`/mpesa/payments/${member.membership_number}`"
            >
              paid
            </router-link>
                  
               
                </td> -->

                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_11_amount }}</small>
               
                </td>
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_12_amount }}</small>
               
                </td>

                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_13_amount }}</small>
               
                </td>

                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_14_amount }}</small>
               
                </td>

                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_15_amount }}</small>
               
                </td>

                
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_16_amount }}</small>
               
                </td>

                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_17_amount }}</small>
               
                </td>

                
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_18_amount }}</small>
               
                </td>
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_19_amount }}</small>
               
                </td>
              
              
              
              
              
           
           

              

              <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
            </tr>
          </tbody>
        </table>
        <br />
        <br />
       
        <div class="row">
          <br />
        </div>
      </div>

      
      <br>
     
      <button @click="exportToPDF">Download </button>


      <!-- <button @click="exportToPDF">Download</button> -->
      <br />
      <router-link to="/">Go back</router-link>
    </base-card>
  </div>
</template>
<script>
//   import axios from "axios";
import html2pdf from "html2pdf.js";
export default {
    
  methods: {
    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [0, 10, 0, 10], //top, left, buttom, right
        filename: "Payments as at " + new Date(Date.now() ).toLocaleString('nl-NL').split(",")[0]  + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
      });
    },
  },

   

  computed: {
      memberdetails() {
            return  this.$store.getters.allMembers

            
        },
        someMembers(){


         return  this.$store.getters.newmembers 
        },
        membercases() {
            return  this.$store.getters.allcases

            
        },
        payments() {
          return  this.$store.getters.allPayments

          
      },
      active_members() {
          return  this.$store.getters.newmembers.filter((col) => col.buffer_amount == 2000).filter((col) => col.case_8_amount == 450).filter((col) => col.processed == 1)

          
      },
      caaseDate(){
        return this.allCases.filter(caseItem => caseItem.id === Number(this.caseId));

      },
      
      memberPayments() {
          return  this.$store.getters.payments//.filter((col) => col.member_id == memberId)

          
      },
      total_registrationFee() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.registration_amount), 0);
    },
    total_buffferAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.buffer_amount), 0);
    },
    total_caseOneAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_1_amount), 0);
    },
    total_caseTwoAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_2_amount), 0);
    },
    total_caseThreeAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_3_amount), 0);
    },
    total_caseFourAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_4_amount), 0);
    },
          total_caseFiveAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_5_amount), 0);
    },
          total_caseSixAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_6_amount), 0);
    },
     total_caseSevenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_7_amount), 0);
    },

    total_caseEightAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_8_amount), 0);
    },
    total_caseNineAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_9_amount), 0);
    },

    total_caseElevenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_11_amount), 0);
    },

    total_caseTwelveAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_12_amount), 0);
    },

    
    total_caseThirteenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_13_amount), 0);
    },
    total_caseFourteenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_14_amount), 0);
    },

    total_caseFifteenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_15_amount), 0);
    },

    total_caseSixteenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_16_amount), 0);
    },

    total_caseSeventeenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_17_amount), 0);
    },
    total_caseEighteenAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_18_amount), 0);
    },
    total_caseNinenteenAMount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_19_amount), 0);
    },
    
    
    
    
    
    
    
    
      


   
   
  },
  created() {
    this.$store.dispatch("Loadmembers");
    this.$store.dispatch('LoadPayments'); 
    this.$store.dispatch('LoadNewmembers');   
    this.$store.dispatch('LoadCases'); 
    

  
  },
};
</script>

<style scoped>
table.myFormat tr td { font-size: 12px; }
</style>
