<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">Shangilia</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
      </li>
      <li v-if="isLoggedIn" class="nav-item">
         
            
            <a class="nav-link" href="/register/case">New case </a>
            
         
          
            
          </li> 
          <li v-if="isLoggedIn" class="nav-item">
           
            
            <a class="nav-link" href="/all/expenses">Report</a>
            
         
          
            
          </li>
          <li v-if="isLoggedIn" class="nav-item">
           
            
            <a class="nav-link" href="/all/payments">payments</a>
           
        
         
           
         </li>
         <li v-if="isLoggedIn" class="nav-item">
           
            
           <a class="nav-link" href="/mpesa/payments">Mpesa </a>
          
       
        
          
        </li>
         <li v-if="isLoggedIn" class="nav-item">
           
            
          <a class="nav-link" href="/create/member">Create member</a>
          
       
        
          
        </li>

        <li v-if="isLoggedIn" class="nav-item">
           
            
           <a class="nav-link" href="/announcement/sms">Annoucement sms</a>
           
        
         
           
         </li>
          <li v-if="isLoggedIn" class="nav-item">
           
            
            <!-- <a class="dropdown-item" href="#" @click="handleLogout">Logout</a> -->
            
         
          
            
          </li>
          <li v-else class="nav-item dropdown">
            <a class="nav-link" href="/member/registration">Member Registration</a>
            
          </li> 


     
     
    </ul>
   
  </div>
  <div v-if="isLoggedIn" class="dropdown">
    <a class="dropdown-item" href="#" @click="handleLogout">Logout</a>

</div>
</nav>
</template>
<style scoped>
.navbar .login-btn {
  position: absolute;
  top: 0px;
  right: 15px;
}
@media (max-width: 767px) {
  .navbar .login-btn {
    right: 75px;
  }
}
</style>

<script>

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("clearToken");
      localStorage.removeItem("token"); // clear your user's token from localstorage
      localStorage.removeItem("user"); // clear your user from localstorage

      this.$router.push("/");
    },
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);

    setInterval(() => {
      this.handleLogout();
    }, 600000);
  },
}
  
 </script>